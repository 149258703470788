import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons';
import styled, { ThemeProvider } from 'styled-components';

import theme from '../stylesheets/theme';
import {
  vaellukset,
  hiihtovaellukset,
  // maastopyoraily,
  vapaalasku,
  verkkokurssit,
} from '../information/camps';

import Layout from '../components/layout';

const StartStyles = styled.div`
  .background-start {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    .background-image {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      min-height: 100vh;
      height: 100%;
      max-height: 100vh;
      filter: brightness(90%);
    }
    .content {
      position: absolute;
      margin: auto;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px 30px;
      .text-content {
        display: flex;
        flex-direction: column;
        margin: auto auto auto;
        padding: 30px 0;
        .title {
          color: ${(props) => props.theme.white};
          text-align: center;
          margin-bottom: 5px;
        }
        .sub-title {
          color: ${(props) => props.theme.white};
          text-align: center;
          font-weight: 400;
          font-size: 20px;
        }
      }
      .scroll-down {
        display: flex;
        margin: 0 auto 2.5vh;
        flex-direction: column;
        p {
          color: ${(props) => props.theme.white};
          font-size: 14px;
          letter-spacing: 1.96px;
          font-weight: 300;
          text-align: center;
        }
        a {
          margin: 0 auto;
          svg {
            width: 30px;
            height: 30px;
            color: ${(props) => props.theme.white};
            animation: bounceDown 2s infinite;
            &:hover {
              opacity: 0.9;
            }
          }
        }
      }
    }
  }

  .about-us {
    margin: 75px 0 0;
    padding: 0 25px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    .about-us-title {
      text-align: center;
    }
    .about-us-subtitle {
      margin: 25px auto;
      /* text-align: center; */
      max-width: 430px;
    }
    .button {
      margin: 0 auto;
      text-align: center;
    }
  }
`;

const CategoryComponentStyles = styled.div`
  display: flex;
  flex-direction: column;
  /* min-height: 50vh; */
  max-width: 1400px;
  margin: 68px auto 0;
  .category-title-container {
    margin: 26px auto;
    .category-title {
      color: ${(props) => props.theme.black};
      text-align: center;
    }
    .category-description {
      color: ${(props) => props.theme.black};
      text-align: center;
    }
  }
  .category-offerings-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    /* align-items: center; */
    justify-content: center;
    @media (min-width: 1000px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
    .category-item {
      display: flex;
      flex-direction: column;
      width: calc(70vh * 0.8);
      min-width: calc(30% - 6px);
      max-width: 400px;
      max-width: calc(100% - 5vw);
      height: 70vh;
      max-height: 500px;
      position: relative;
      margin-bottom: 26px;
      margin: 1.25vw auto;
      @media (min-width: 1000px) {
        width: auto;
        max-width: 70vh;
        height: 35vw;
        margin: 0.8vw;
      }
      @media (min-width: 1400px) {
        margin: 16px;
      }
      .category-item-image {
        position: relative;
        min-width: 100%;
        min-height: 100%;
      }
      .category-text-content {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 8%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.3);
        &:hover {
          background: rgba(0, 0, 0, 0.1);
          transition: all 0.3s ease;
        }
        .category-card-title {
          text-align: center;
          color: ${(props) => props.theme.white};
          /* max-width: calc(100% - 8px); */
          margin-bottom: 10px;
        }
        .category-card-dates {
          color: ${(props) => props.theme.white};
          margin: 5px auto 0;
          padding: 0 5px;
          text-align: center;
          display: none;
          @media (min-width: 350px) {
            display: flex;
          }
        }
      }
    }
  }
`;

const CategoryComponent = ({ category, offerings }) => (
  <CategoryComponentStyles>
    <div className="category-title-container">
      <h3 className="category-title">{category.title}</h3>
      <h5 className="category-description">{category.description}</h5>
    </div>
    <div className="category-offerings-container">
      {offerings.map((offering) => (
        <Link className="category-item" key={offering.name} to={offering.link}>
          <GatsbyImage
            image={offering.gatsbyImageData}
            className="category-item-image"
            alt="image"
          />
          <div className="category-text-content">
            <h3 className="category-card-title">{offering.title}</h3>
            {offering.dates.map((date) => (
              <h4 className="category-card-dates" key={date}>
                {date}
              </h4>
            ))}
          </div>
        </Link>
      ))}
    </div>
  </CategoryComponentStyles>
);

// eslint-disable-next-line react/prop-types
const IndexPage = ({ data }) => {
  const keys = Object.keys(data);
  const hikes = vaellukset.map((offering, i) => ({
    ...offering,
    gatsbyImageData: data[keys[i + 2]].childImageSharp.gatsbyImageData,
  }));
  const skiing = hiihtovaellukset.map((offering, i) => ({
    ...offering,
    gatsbyImageData: data[keys[i + 4]].childImageSharp.gatsbyImageData,
  }));
  // const biking = maastopyoraily.map((offering, i) => ({
  //   ...offering,
  //   gatsbyImageData: data[keys[i + 10]].childImageSharp.gatsbyImageData,
  // }));
  const freeskiing = vapaalasku.map((offering, i) => ({
    ...offering,
    gatsbyImageData: data[keys[i + 9]].childImageSharp.gatsbyImageData,
  }));
  const onlineCourses = verkkokurssit.map((offering, i) => ({
    ...offering,
    gatsbyImageData: data[keys[i + 10]].childImageSharp.gatsbyImageData,
  }));

  return (
    <Layout
      seo={{
        title: 'Discover Lapland',
        // keywords={[`gatsby`, `application`, `react`]}
        url: 'https://www.discoverlapland.fi',
        image: `https://www.discoverlapland.fi${data.imageSEO.childImageSharp.gatsbyImageData.src}`,
        description:
          'Discover Lapland järjestää Tunturi-Lapin ja Käsivarren alueella eksklusiivisia, pienryhmissä toteutettavia naisten retkiä ympäri vuoden.',
      }}
    >
      <ThemeProvider theme={theme}>
        <StartStyles>
          {/* HERO */}
          <div className="background-start">
            <GatsbyImage
              image={data.startpageBg.childImageSharp.gatsbyImageData}
              className="background-image"
              alt="image"
            />
            <div className="content">
              <div className="text-content">
                <h1 className="title">Naisten retket Lapissa</h1>
                <h3 className="sub-title">
                  Kestävästi ja luontoa kunnioittaen ikimuistoisia hetkiä ja
                  kokemuksia!
                </h3>
              </div>
              <div className="scroll-down">
                <Link to="/#offerings">
                  <FontAwesomeIcon icon={faLongArrowAltDown} />
                </Link>
              </div>
            </div>
          </div>
          <CategoryComponent
            category={{
              title: 'Hiihtovaellus',
              description: 'Hiljaa hiihtäen tulee kaunis latu',
            }}
            offerings={skiing}
          />
          <CategoryComponent
            category={{
              title: 'Vapaalasku',
              description: 'Leppoisat ja ikimuistoiset retket lumella',
            }}
            offerings={freeskiing}
          />
          {/* <CategoryComponent
            category={{
              title: 'Maastopyöräily',
              description: 'Polkien tunturimaisemissa',
            }}
            offerings={biking}
          /> */}
          <CategoryComponent
            category={{
              title: 'Verkkokurssit',
              description: 'Opi, missä ikinä oletkin!',
            }}
            offerings={onlineCourses}
          />
          <CategoryComponent
            category={{
              title: 'Vaellukset',
              description: 'Hyvässä seurassa oppien uutta',
            }}
            offerings={hikes}
          />
        </StartStyles>
      </ThemeProvider>
    </Layout>
  );
};

export const query = graphql`
  {
    startpageBg: file(relativePath: { eq: "images/freeski/pallas-feb-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageSEO: file(relativePath: { eq: "images/freeski/pallas-feb-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1366, layout: FIXED)
      }
    }
    hike1: file(relativePath: { eq: "images/hikes/2022-hetta-pallas-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    hike2: file(relativePath: { eq: "images/hikes/halti-2020-6.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    crossCountry1: file(
      relativePath: { eq: "images/crosscountry/kaamoshiihto-7.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    crossCountry3: file(
      relativePath: {
        eq: "images/crosscountry/hettapallas-hiihtovaellus-2023-2.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    crossCountry4: file(
      relativePath: { eq: "images/crosscountry/Ahkio-Halti.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    bike1: file(
      relativePath: { eq: "images/mtb/ruska/anette_hetta_remote-46.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    freeski1: file(relativePath: { eq: "images/freeski/yllas-finlav-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    freeski2: file(
      relativePath: { eq: "images/freeski/kilpisjarvi-freeski-1.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    verkkokurssi1: file(
      relativePath: { eq: "images/verkkokurssi/verkkokurssi-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

export default IndexPage;
